.userNameIconMedium,
.userNameIconLarge,
.userNameIconSmall,
.userNameIconWelcomeCard {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.userNameIconWelcomeCard {
  width: 60px;
  height: 60px;
}

.userNameIconWelcomeCard vega-font {
  font-size: 24px !important;
}

.userNameIconLarge {
  width: 50px;
  height: 50px;
  font-size: 1.2rem;
}

.userNameIconSmall {
  width: 25px;
  height: 25px;
  font-size: 0.8rem;
}

@media screen and (max-width: 1340px) {
  .userNameIconWelcomeCard vega-font {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 992px) {
  .userNameIconWelcomeCard {
    width: 50px;
    height: 50px;
    font-size: 20px !important;
  }

  .userNameIconMedium {
    width: 30px;
    height: 30px;
    font-size: 0.9rem;
  }

  .userNameIconSmall {
    width: 20px;
    height: 20px;
    font-size: 1rem;
  }
}
