.charts {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.disclaimer {
  font-size: 0.8rem;
  font-weight: bold;
}

.chartContainer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chartContainerFlex {
  width: 100%;
}

@media screen and (max-width: 1540px) {
  .chartContainerFlex {
    flex-direction: column !important;
    gap: 30px !important;
  }
}
