.cardText {
  grid-column: 1 / span 3;
}

.CTAbuttons {
  grid-column: 4;
}

@media (max-width: 768px) {
  .cardText {
    grid-column: 1 / span 4;
  }
  .CTAbuttons {
    grid-column: 1 / span 4;
  }
}
