.container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 24px;
}

.buttonRow {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 100%;
}

.buttonRow > button {
  margin-right: 0.6rem;
}

.legendRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.select {
  max-width: 275px;
}

@media screen and (max-width: 1143px) {
  .buttonRow {
    padding-left: 0;
    justify-content: flex-start;
    align-items: center;
    gap: 0.3rem;
    max-width: 100%;
  }

  .buttonRow > button {
    margin-right: 0rem;
  }

  .legendRow {
    flex-direction: column;
    align-items: flex-start;
  }

  .container {
    flex-direction: column;
    gap: 1rem;
  }

  .select {
    max-width: 100%;
  }
}
