.versionWarning {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: center;
  align-self: center;
  justify-self: center;
}

.circleLegend1,
.circleLegend2 {
  border-radius: 50%;
  border: 1px solid var(--border);
  height: 15px;
  width: 15px;
}

.displayRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 0.5rem;
}

.chartContainer,
.chartContainerRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 50%;
  height: 260px;
  border-radius: 12px;
}

.chartContainerRow {
  width: 100%;
}

.title {
  font-size: 1rem;
  margin-right: auto;
  font-weight: bold;
  line-height: 3rem;
  display: flex;
}

.help {
  cursor: default;
  justify-self: center;
  align-self: center;
  margin-top: 5px;
  margin-left: 5px;
}

.tooltip {
  height: auto;
  padding: 0.5rem;
  min-width: 4rem;
  opacity: 0.9;
  width: auto;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 0.5rem;
}

.emptyChart {
  height: 95%;
  width: 95%;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1340px) {
  .chartContainer {
    margin-top: 0.5rem;
  }
}
