.initials {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.welcomeMessage {
  display: flex;
  align-items: center;
}

.welcomeCard {
  height: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1340px) {
  .initials {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
}
