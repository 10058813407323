.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

.select {
  max-width: 275px;
}

@media screen and (max-width: 992px) {
  .row {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.2rem;
    max-width: 100%;
  }

  .select {
    max-width: 100%;
  }
}
