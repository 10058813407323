.labelMarker {
  padding: 5px;
  cursor: pointer;
  transition: all 500ms;
  border: 1px solid transparent;
}

.labelMarker:hover {
  border: 1px solid var(--primary);
}

.labelMarker.selected {
  border: 1px solid var(--primary);
}
