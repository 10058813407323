.paymentTypeChartContainer {
  width: 100%;
  height: 100%;
  min-height: 324px;
}

.selectContainer {
  width: 275px;
}

@media screen and (max-width: 767px) {
  .paymentTypeChartContainer {
    min-height: auto;
  }
}

@media screen and (max-width: 575px) {
  .selectContainer {
    width: 100%;
  }
}
