.legendCircle {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.legend {
  padding-top: 20px !important;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
