.productsCard {
  max-width: 656px;
  border-radius: 12px;
}

.link {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.link span {
  padding-right: 0.5rem;
}

.title {
  gap: 8px;
}

.description {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

.ellipse {
  background-color: #F0F3F7;
  width: 108px;
  height: 108px;
  top: 15px;
  left: 4px;
  gap: 0px;
  border-radius: 50%;
  text-align: center;
}

.rectangle {
  background-color: #F0F3F7;
  width: 256px;
  top: 15px;
  left: 4px;
  gap: 0px;
  border-radius: 8px;
  text-align: center;
}

.image {
  width: 116px;
  height: 69px;
  top: 34px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  opacity: 0px;
  margin: auto;
  position: cen;
}